<template>
<Page id="home" :title="tournamentname">
    <Loader v-if="loading"></Loader>
    <b-container v-else>
        <b-row v-if="tournament.endmovie">
            <b-col>
                <Movie :title="'Toppstriden i ' + tournament.name" :src="endmoviesrc" :caption="'Se filmen för att följa toppstriden i ' + tournament.name"></Movie>
            </b-col>
        </b-row>
        <b-row v-if="todaygames.length > 0 || tomorrowgames.length > 0">
            <b-col v-if="todaygames.length > 0" cols="12" class="my-3">
                <Tips title="Dagens tips" :games="todaygames"></Tips>
            </b-col>
            <b-col v-if="tomorrowgames.length > 0" cols="12" class="my-3">
                <Tips title="Morgondagens tips" :games="tomorrowgames"></Tips>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="6" class="border-bottom my-3">
                <News></News>
            </b-col>
            <b-col cols="12" lg="6" class="border-bottom my-3">
                <Payment></Payment>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="6" class="border-bottom my-3">
                <Gbook></Gbook>
            </b-col>
            <b-col cols="12" lg="6" class="border-bottom my-3">

            </b-col>
        </b-row>
    </b-container>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Movie = () => import("@/components/common/Movie");
const Loader = () => import("@/components/miscellaneous/Loader");

const Gbook = () => import("@/components/dashboard/Gbook");
const Payment = () => import("@/components/dashboard/Payment");
const News = () => import("@/components/dashboard/News");
const Tips = () => import("@/components/dashboard/Tips");

export default {
    name: "Home",
    components: {
        Alert,
        Page,
        Loader,
        Gbook,
        Payment,
        News,
        Movie,
        Tips
    },
    data() {
        return {};
    },
    metaInfo() {
        return {
            title: this.tournamentname
        };
    },
    computed: {
        loading() {
            return this.$store.getters["tournamentsg/tournamentloading"] ||
                this.$store.getters["dahsboard/loading"] ||
                this.$store.getters["teams/teamsloading"];
        },
        tournamentname() {
            return this.loading ? "..." : "Välkommen till " + this.tournament.name;
        },
        tournament() {
            var data = this.$store.getters["tournamentsg/tournament"];
            return this.loading ? false : data;
        },
        endmoviesrc() {
            return "/movies/winners/" + this.tournament.endmovie;
        },
        news() {
            var data = this.$store.getters["dashboard/dashboard"];
            if (data && data.news)
                return data.news;
            return [];
        },
        payment() {
            var data = this.$store.getters["dashboard/dashboard"];
            if (data && data.payment)
                return data.payment;
            return [];
        },
        todaygames() {
            var data = this.$store.getters["dashboard/dashboard"];
            if (data && data.games && data.games.today)
                return data.games.today;
            return [];
        },
        tomorrowgames() {
            var data = this.$store.getters["dashboard/dashboard"];
            if (data && data.games && data.games.tomorrow)
                return data.games.tomorrow;
            return [];
        },
        users() {
            var data = this.$store.getters["winners/winners"];
            if (data)
                return data.userstats;
            return [];
        }
    },
    methods: {
        currency(value) {
            return this.$formatter.formatCurrency(value, 0);
        },
        PlacementUser(placement) {
            var users = this.users;
            if (users[placement])
                return users[placement];
            return false;
        },
        useravatar(user) {
            return this.$images.userAvatar(user.userid);
        },
        userlink(item) {
            var user = {
                username: item.username,
                id: item.userid
            };
            return this.$url.userurl(user);
        },

    },
    mounted() {
        this.$store.dispatch("dashboard/getDashboard");        
    },
};
</script>
